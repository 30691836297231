import { HTMLAttributes } from 'react';
import styled from 'styled-components';

type SubTextGrayAttrs = {
  textAlign: string;
};

export const SubTextGray = styled.p.attrs<
  SubTextGrayAttrs,
  HTMLAttributes<HTMLParagraphElement> & SubTextGrayAttrs
>(p => ({
  className: `text-${p.textAlign} mt-2  text-sm  text-gray-600`,
  ...p,
}))``;
