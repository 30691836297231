import styled from 'styled-components';

interface WarningFeedbackAttrs {
  role: string;
}

export const WarningFeedback = styled.div.attrs((p: WarningFeedbackAttrs) => ({
  className: 'bg-yellow-100 rounded-lg py-5 px-6 mb-4 text-base text-yellow-700 mb-3',
  role: p.role || 'alert',
}))`
  margin-top: 15px;
`;
