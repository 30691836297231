import { GetServerSideProps } from 'next';
import { getCsrfToken, signIn } from 'next-auth/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FormEvent, ReactElement, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { signInSSRAuth } from '@/auth/authorization';
import AuthenticationLayout from '@/components/layouts/authentication-layout';
import PageLayout from '@/components/layouts/page-layout';
import { GenericTextLink, WarningFeedback } from '@/components/shared-components';

export const getServerSideProps: GetServerSideProps = signInSSRAuth(async context => {
  const { query } = context;

  return {
    props: {
      csrfToken: await getCsrfToken(context),
      from_reset_page: query.from_reset_page ? true : false,
    },
  };
});

type SignInProps = {
  csrfToken: string;
  from_reset_page: boolean;
};

const SignIn = ({ csrfToken, from_reset_page }: SignInProps) => {
  const router = useRouter();
  const [signInError, setSignInError] = useState<string>('');
  const [emailInput, setEmailInput] = useState<string>('');
  const [passwordInput, setPasswordInput] = useState<string>('');

  useEffect(() => {
    const { error } = router.query;
    if (error) setSignInError('Há um erro no email ou senha');
  }, [router]);

  const loginSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await signIn('credentials', { email: emailInput, password: passwordInput });
  };

  if (from_reset_page) toast.success('Senha alterada com sucesso!');

  return (
    <>
      <AuthenticationLayout tittle={'Olá, boas-vindas'} subTittle={'Acesse a plataforma da Kenzie'}>
        <form className="space-y-6" onSubmit={loginSubmit} method="post">
          <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
          <input type="hidden" name="remember" defaultValue="true" />
          <div>
            <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
              Email Kenzie
            </label>
            <div className="mt-1">
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                onChange={e => setEmailInput(e.target.value)}
              />
            </div>
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Senha
            </label>
            <div className="mt-1">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                onChange={e => setPasswordInput(e.target.value)}
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember_me"
                name="remember_me"
                type="checkbox"
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">
                Lembrar de mim
              </label>
            </div>

            <div className="text-sm">
              <Link href="/password-recovery/request" passHref>
                <GenericTextLink>Esqueci minha senha</GenericTextLink>
              </Link>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Entrar
            </button>
            {signInError && <WarningFeedback>{signInError}</WarningFeedback>}
          </div>
        </form>
      </AuthenticationLayout>
    </>
  );
};

export default SignIn;

SignIn.getLayout = (page: ReactElement) => {
  return <PageLayout title="Login - Kenzie Backoffice">{page}</PageLayout>;
};
