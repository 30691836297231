import Head from 'next/head';
import { ReactNode } from 'react';

type PageLayoutProps = {
  children: ReactNode;
  title: string;
};

export default function PageLayout({ children, title = 'Kenzie Backoffice' }: PageLayoutProps) {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/logo-kenzie.jpeg" />
      </Head>

      {children}
    </div>
  );
}
