import Image from 'next/image';
import type { ReactElement } from 'react';

import { SubTextGray } from '@/components/shared-components';
import kenzieLogo from '@/images/kenzie-logo.svg';

type AuthenticationLayoutProps = {
  children: ReactElement;
  tittle: string;
  subTittle: string;
};

const AuthenticationLayout = ({ children, tittle, subTittle }: AuthenticationLayoutProps) => {
  return (
    <>
      <div
        className="flex flex-col justify-center min-h-screen py-12 sm:px-6 lg:px-8"
        style={{ backgroundColor: '#F9FAFB' }}
      >
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="flex flex-col items-center justify-center">
            <Image
              className=""
              src={kenzieLogo}
              width={42}
              height={45}
              alt="Logotipo da Kenzie Academy"
            />
          </div>
          <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900">{tittle}</h2>
          <SubTextGray textAlign={'center'}>{subTittle}</SubTextGray>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="px-4 py-8 bg-white shadow sm:rounded-lg sm:px-10">{children}</div>
        </div>
      </div>
    </>
  );
};

export default AuthenticationLayout;
